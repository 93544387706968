var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "current" },
    [
      _c("el-autocomplete", {
        staticClass: "inline-input",
        staticStyle: { width: "180px" },
        attrs: {
          maxlength: 8,
          size: "12",
          valueKey: "plateNumber",
          "fetch-suggestions": _vm.querySearchAsync,
          placeholder: "请输入内容",
          "trigger-on-focus": false,
        },
        on: { select: _vm.handleSelect },
        model: {
          value: _vm.plateNumber,
          callback: function ($$v) {
            _vm.plateNumber = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "plateNumber",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }